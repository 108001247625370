import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PageHome,PageNotFound,PageRegister,PageDev,PageQR,PageVote,PageLogout} from './Pages';
const Router =() =>{
    return (
        <Routes>
            <Route path="/" element={<PageHome />} />
            <Route path="/Register" element={<PageRegister />} />
            <Route path="/QR" element={<PageQR />} />
            <Route path="/Vote" element={<PageVote />} />
            <Route path="/Logout" element={<PageLogout />} />

            <Route path="*" element={<PageNotFound/>} />
        </Routes>
    )
}

export default React.memo(Router)