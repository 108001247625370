import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../API';
import {IsLogged, KeyDownNumber} from '../Utils/Function';
import '../App.css';
import {KeyDownMobile} from '../Utils/Function';
import MainHome from './Home/MemberHome';
const PageRegister =()=> {
    
    const [_PageMode,setPageMode] = useState(1);
    const [_OTP,setOTP] = useState(false);
    const [_Mobile,setMobile] = useState('');
    const [_OTPCode,setOTPCode] = useState('');
    const [_OTPInfo,setOTPInfo] = useState(null);
    const [_Name,setName] = useState(null);
    const [_Email,setEmail] = useState(null);
    const [_Age,setAge] = useState(null);
    useEffect(() => {
        (async () => {
            localStorage.removeItem('pgfec_login');
            localStorage.removeItem('pgfec_member');
            localStorage.removeItem('pgfec_name');
        })();

        return ()=>{ }
    }, []);
    
    const _RequestOTP = async()=>{
        if(String(_Mobile).length != 10){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบหมายเลขโทรศัพท์',
            });
            return;
        }
        setOTPCode('');
        setOTPInfo(null);
        Swal.fire({
            title: 'โปรดรอสักครู่',
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        API({method : "get",url:"/OTP/Register/"+_Mobile}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setOTPInfo(res.data.Response);
            setOTP(true);
            Swal.close();
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });

    }
    const _ClearLogin = ()=>{
        localStorage.removeItem('pgfec_login');
        localStorage.removeItem('pgfec_member');
        localStorage.removeItem('pgfec_name');

    }
    const _ConfirmOTP = async()=>{
        if(String(_OTPCode).length != 6){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบรหัส OTP',
            });
            return;
        }
        setOTPCode('');

        Swal.fire({
            title: 'โปรดรอสักครู่',
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        const _reqObj = {
            OTPRefID:_OTPInfo.OTPRefID,
            RefCode: _OTPInfo.RefCode,
            OTPCode: _OTPCode
        };

        API({method : "post",url:"/OTP/Register/"+_Mobile,data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setOTP(true);
            Swal.close();
            localStorage.removeItem('pgfec_login');
            localStorage.removeItem('pgfec_member');
            localStorage.removeItem('pgfec_name');

            localStorage.setItem('pgfec_login',res.data.Response.Session);
            localStorage.setItem('pgfec_member',res.data.Response.MemberID);
            localStorage.setItem('pgfec_name',res.data.Response.Name);
            
            setPageMode(3);
            setOTPInfo(null);
            setOTPCode('');
            setOTP(false);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });

    }

    const _Save = async()=>{
        if(String(_Mobile).length != 10){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบหมายเลขโทรศัพท์',
            });
            return;
        }

        if(String(_Mobile).length === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ชื่อ - นามสกุล',
            });
            return;
        }

        Swal.fire({
            title: 'โปรดรอสักครู่',
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
        const _reqObj = {
            Mobile:_Mobile,
            Name:_Name,
            Email:_Email,
            Age:_Age
        }
        API({method : "post",url:"/Register/Save",data:_reqObj}).then(res=>{
            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            localStorage.removeItem('pgfec_login');
            localStorage.removeItem('pgfec_member');
            localStorage.removeItem('pgfec_name');

            localStorage.setItem('pgfec_login',res.data.Response.Session);
            localStorage.setItem('pgfec_member',res.data.Response.MemberID);
            localStorage.setItem('pgfec_name',res.data.Response.Name);
            Swal.close();
            window.location.href ='/';
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
        });
    }
    return (
        <>
            <React.Fragment>
                <div className='card'>
                        <div className='card-body' style={{minHeight:'300px'}}>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h3>ลงทะเบียน</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h3 className='fs-bold'>e-PassPort</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <h5 className='fs-5'>ลงทะเบียนเข้าร่วมสนุกภายในงาน</h5>
                                </div>
                            </div>
                            <div className="mt-2 mb-2 col-md-4">
                                <label className="form-label fw-semibold">ชื่อ - นามสกุล</label>
                                <input type="text" value={_Name} onChange={(e)=>{setName(e.target.value);}} className="form-control" autoComplete='one-time-code'/>
                            </div>
                            <div className="mt-2 mb-2 col-md-4">
                                <label className="form-label fw-semibold">อีเมล์</label>
                                <input type="email" value={_Email} onChange={(e)=>{setEmail(e.target.value);}} className="form-control" autoComplete='one-time-code'/>
                            </div>
                            <div className="mt-2 mb-2 col-md-4">
                                <label className="form-label fw-semibold">อายุ</label>
                                <input type="tel" value={_Age}  onKeyDown={(e)=>{KeyDownNumber(e);}} onChange={(e)=>{setAge(e.target.value);}} className="form-control text-center" autoComplete='one-time-code'/>
                            </div>
                            <div className="mt-2 mb-2 col-md-4">
                                        <label className="form-label fw-semibold">หมายเลขโทรศัพท์มือถือ</label>
                                        <input type="tel" pattern="[0-9]{3}-[0-9]{4}-[0-9]{3}" onKeyDown={(e)=>{KeyDownMobile(e);}} maxLength={10} value={_Mobile} onChange={(e)=>{setMobile(e.target.value);}} className="form-control text-center" autoComplete='one-time-code'/>
                                    </div>
                            <div className='row mt-4 mb-4'>
                                <div className='col-lg-12 text-center'>
                                    <button className='btn btn-lg text-white btn-p btn-m' onClick={()=>{_Save();}}>ลงทะเบียน</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>

        </>
    )
}

export default React.memo(PageRegister)
