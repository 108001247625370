import React,{useEffect} from "react";
const Content =(props) =>{
    // eslint-disable-next-line
    useEffect(() => {
        (async () => {
        })();
        
        return ()=>{ 
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <main role='main' className='flex-shrink-0 mt-2 mb-2' style={{paddingTop:'240px',paddingLeft:'20px',paddingRight:'20px'}}>
            {props.children}
            </main>
        </React.Fragment>
    )
}
export default React.memo(Content)