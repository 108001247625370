import React,{useRef,useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import API from '../../API';
import {IsLogged, KeyDownNumber,GetAPIURL} from '../../Utils/Function';
import '../../App.css';
import {KeyDownMobile} from '../../Utils/Function';
const PageVote =()=> {
    const [_Vote,setVote] = useState([]);
    const [_VoteTeam,setVoteTeam] = useState(null);
    useEffect(() => {
        (async () => {
            if(localStorage.getItem('pgfec_login') === undefined ){
                window.location.href = '/';
                return;
            }
            _Loading();
        })();

        return ()=>{ }
    }, []);
    const _Loading = async()=>{
        setVote([]);
        setVoteTeam(null);
        API({method : "get",url:"/Vote/"+localStorage.getItem('pgfec_login')}).then(res=>{
            if (!res.data.Status) {
                return;
            }
            console.log(res.data);            
            setVoteTeam(res.data.Response.VoteTeamID);
            setVote(res.data.Response);
        }).catch(res => {
        });
    }
    const _ClickTeam = async(Vote,VoteTeam) =>{
        //if(_VoteTeam != null) return;
        Swal.fire({
            icon: 'warning',
            title: 'ทีม '+VoteTeam.TeamName+' ?',
            showCancelButton : true,
            confirmButtonText: 'เลือก',
            confirmButtonColor:'#d33',
            cancelButtonText:'กลับ'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : "post",url:"/Vote/"+localStorage.getItem('pgfec_login')+'/'+Vote.VoteID+'/'+VoteTeam.VoteTeamID}).then(res=>{
                if (!res.data.Status) {
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message,
                    });
                    return;
                }
                _Loading();
                Swal.fire({
                    icon: 'success',
                    title: res.data.Message,
                    text: 'โปรดกรุณารอสักครู่',
                    timer: 3000,
                    showCancelButton: false,
                    showConfirmButton: false
                }).then(()=>{
                    
                });

            }).catch(res => {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
            });
        });
    }
    return (
        <>
            <React.Fragment>

            <div className='row'>
                <div className='col-12'>
                    <div className='card'>
                            <div className='card-body' style={{minHeight:'300px'}}>
                                <div className='row'>
                                    <div className='col-lg-12 text-center'>
                                        <h3>ทายผลการแข่งแกม</h3>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'> <hr/></div>
                                </div>
                                {(_Vote.map((v,i)=>(
                                    <>
                                    <div className='row'>
                                        <div className='col-lg-12 text-center'>
                                            <h3 className='fs-bold'>{v.VoteName}</h3>
                                        </div>
                                    </div>

                                    <div className='row mt-2 mb-2'>
                                        <div className='col-lg-12 text-center'>
                                            <img src={GetAPIURL()+'/Vote/'+v.VoteID+'/Image'} style={{maxWidth:'300px',maxHeight:'300px'}}/>
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                    {v.Teams.map((tv,ti)=>(
                                    <div class="col-6">
                                        <div class="p-2 itemicon text-center from-control">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <img src={GetAPIURL()+'/Vote/Team/'+tv.VoteTeamID+'/Image'} style={{maxWidth:'120px',maxHeight:'300px'}} onClick={()=>{if(v.VoteTeamID == null && v.Active === true){_ClickTeam(v,tv);}}} className={(v.VoteTeamID  === tv.VoteTeamID ? '':'itemInActive')}/>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <b className='fs-5'>{tv.TeamName}</b>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <b className='fs-5 text-primary'>{tv.Votes}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                    </div>
                                    </>
                                )))}
                                
                            </div>
                    </div>
                </div>
            </div>
            <div className='row mt-2 mb-2'>
                <div className='col-12'>
                    <div className='row mt-2'>
                        <div className='col-12 text-center'>
                            <button className='btn btn-primary btn-m' onClick={()=>{window.location.href='/';}}>กลับหน้าหลัก</button>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>

        </>
    )
}

export default React.memo(PageVote)
